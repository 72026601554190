<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td class="truncate">
                {{ item.name }}
            </td>
            <td class="truncate">
                <v-btn small @click="select(item)" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
    props: ['items'],
    methods:{
        ...mapMutations(['clearTranslationsTable']),
        select(item){
            this.clearTranslationsTable();
            this.$router.push('translations/'+item.name)
        }
    }
}
</script>
