<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                data-cy="stepper"
                                :rules="[() => getNamespaceErrors.stepOne.validation]"
                                editable
                                step="1"
                                >{{
                                    $t('translation:informations')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                 <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t(
                                                    'translation:addingNamespace'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="
                                                getNamespaceValues.namespace
                                            "
                                            :label="$t('translation:namespace')"
                                            :error-messages="
                                                getNamespaceErrors.stepOne.namespace
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            step:1
        }
    },
    computed: {
        ...mapGetters(['getNamespaceValues', 'getNamespaceErrors']),
    },
}
</script>
