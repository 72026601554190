<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <AdminHeader
                    :registryTitle="$t('global:translations')"
                    :registryUrl="'translations'"
                    @clearSearch="clearTable"
                />
                <Table                    
                    :headers="headers"
                    :length="getNamespacesTable.pages"
                    :page="getNamespacesTable.page"
                    :defaultFields="getNamespacesSearchFields.searchFields"
                    @search="setSearch"
                    @change-page="setPage"
                    @sort="setSort"
                >
                    <Columns slot="columns" :items="getNamespacesTable.items" />
                </Table>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                @click="openModal"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-briefcase-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('translation:createNamespace') }}</span>
                    </v-tooltip>
                </div>
                <Modal
                    :title="$t('translation:addingNamespace')"
                    :open="open"
                    :height="'250'"
                    :width="'450'"
                    v-on:close="closeModal"
                >
                    <Content slot="AEContent" />
                    <Buttons slot="buttons" @closeModal="closeModal" />
                </Modal>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Header from './../../../components/Admin/Header/Header'
import Columns from './../../../components/Admin/Translations/Table/Columns'
import Content from '../../../components/Admin/Translations/Modal/Content.vue'
import Buttons from '../../../components/Admin/Translations/Modal/Buttons.vue'
import store from './../../../store/index'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Header,
        Columns,
        Content,
        Buttons,
    },

    methods: {
        ...mapActions([]),
        ...mapMutations(['setNamespaceTable', 'clearNamespaceTable','clearNamespaceValues','clearTranslationErrors']),
        setSearch(search) {
            this.setNamespaceTable({ search, page: 1 })
        },
        setPage(page) {
            this.setNamespaceTable({ page })
        },
        openModal() {
            this.clearTranslationErrors();
            this.clearNamespaceValues();
            this.open = true
        },
        closeModal() {
            this.open = false
        },
        clearTable() {
            this.clearNamespaceTable()
            this.setNamespaceTable()
        },
        setSort(sort) {
            this.setNamespaceTable({sort})
        },
    },
    computed: {
        ...mapGetters(['getNamespacesTable', 'getNamespacesSearchFields']),
    },

    data() {
        return {
            open: false,
            action: '',
            headers: [
                {
                    text: this.$t('translation:namespace'),
                    value: 'name',
                    width: '96%',
                },
                {
                    value: 'action',
                    width: '4%',
                    text: '',
                    sortable: false,
                },
            ],
        }
    },
    mounted() {
        this.setNamespaceTable()
    },
    beforeRouteEnter(to, from, next) {
        if (
            from.matched[1] &&
            from.matched[1].path !== '/translations/:namespace'
        )
            store.commit('clearNamespaceTable')
        next()
    },
}
</script>
